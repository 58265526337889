<template>
  <div></div>
</template>

<script>
export default {
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
  },
  mounted() {
    this.getAppMenu();
  },
  methods: {
    async getAppMenu() {
      try {
        const params = [this.groupId];

        const response = await this.$api.groupMenus.list(...params);

        const menu = response.data.data.find(
          (menu) => menu.slug === "ionic-app-sidemenu",
        );

        this.$router.push({
          name: "app_page_edit",
          params: { menu_id: menu.id },
        });
      } catch (error) {
        if (error) {
          this.errorMessageShow(error);
          return;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
